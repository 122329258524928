import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { Box } from "@twilio-paste/core/box";
// import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";

export const PreEngagementFormPhase = () => {
    const { name, phone, departamento, tipo } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const dispatch = useDispatch();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const valDpto = departamento && departamento !== '' ? departamento : 'Santa Cruz';
            const valTipo = tipo && tipo !== '' ? tipo : 'Asistencia Médica';
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    phone,
                    departamento: valDpto,
                    tipo: valTipo
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...introStyles} as="p">
                    Por favor completar:
                </Text>
                <Box {...fieldStyles}>
                    <Label htmlFor="name">Nombre completo: *</Label>
                    <Input
                        type="text"
                        placeholder="Escriba su nombre"
                        name="name"
                        data-test="pre-engagement-chat-form-name-input"
                        value={name}
                        onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                        required
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="phone">Número de teléfono: *</Label>
                    <Input
                        type="number"
                        placeholder="Escriba su telefono"
                        name="phone"
                        data-test="pre-engagement-chat-form-phone-input"
                        value={phone}
                        onChange={(e) => dispatch(updatePreEngagementData({ phone: e.target.value }))}
                        required
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="departamento">Departamento: *</Label>
                    <Select id="departamento" name="departamento" onChange={(e) => dispatch(updatePreEngagementData({ departamento: e.target.value }))}>
                      <Option value="Santa Cruz">Santa Cruz</Option>
                      <Option value="La Paz">La Paz</Option>
                      <Option value="Cochabamba">Cochabamba</Option>
                      <Option value="Pando">Pando</Option>
                      <Option value="Beni">Beni</Option>
                      <Option value="Oruro">Oruro</Option>
                      <Option value="Potosi">Potosi</Option>
                      <Option value="Chuquisaca">Chuquisaca</Option>
                      <Option value="Tarija">Tarija</Option>
                      <Option value="Exterior">Exterior</Option>
                    </Select>
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="tipo">Tipo de consulta: *</Label>
                    <Select id="tipo" name="tipo" onChange={(e) => dispatch(updatePreEngagementData({ tipo: e.target.value }))}>
                      <Option value="Asistencia Médica">Aprobación de orden médica</Option>
                      <Option value="Cobranzas">Consulta sobre plan de pagos</Option>
                      <Option value="Masivos">Asistencia productos masivos</Option>
                    </Select>
                </Box>

                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                    INICIAR CHAT
                </Button>
            </Box>
        </>
    );
};
