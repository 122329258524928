import { Notification } from "./store/definitions";

/*
 * const exampleNotification: Notification = {
 *     id: "ExampleNotification",
 *     dismissible: true,
 *     message: "Example notification",
 *     type: "neutral"
 * };
 */

const shortenFileName = (string: string, maxChar = 50) => {
    const [, filename, fileExtension] = string.match(/^(.+)(\.[\S]*)$/) || [];

    return `${filename.substr(0, maxChar)}[...]${fileExtension || ""}`;
};

const fileAttachmentAlreadyAttachedNotification = ({ fileName }: { fileName: string }): Notification => ({
    id: `FileAttachmentAlreadyAttachedNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(fileName)} ya ha sido adjuntado.`,
    type: "error",
    timeout: 10000
});

const fileAttachmentInvalidSizeNotification = ({
    fileName,
    maxFileSize
}: {
    fileName: string;
    maxFileSize: string;
}): Notification => ({
    id: `FileAttachmentInvalidSizeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(
        fileName
    )} no puede ser adjuntado, el archivo es mas grande de ${maxFileSize}`,
    type: "error",
    timeout: 10000
});

const fileAttachmentInvalidTypeNotification = ({ fileName }: { fileName: string }): Notification => ({
    id: `FileAttachmentInvalidTypeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(
        fileName
    )} no puede ser adjuntado, intenta otro tipo de archivo.`,
    type: "error",
    timeout: 10000
});

const fileDownloadInvalidSizeNotification = ({
    fileName,
    maxFileSize
}: {
    fileName: string;
    maxFileSize: string;
}): Notification => ({
    id: `FileDownloadInvalidSizeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(
        fileName
    )} no puede ser descargado, el archivo es mas grande de ${maxFileSize}`,
    type: "error",
    timeout: 10000
});

const fileDownloadInvalidTypeNotification = ({ fileName }: { fileName: string }): Notification => ({
    id: `FileDownloadInvalidTypeNotification_${Math.random()}`,
    dismissible: true,
    message: `${shortenFileName(fileName)} no puede ser descargado.`,
    type: "error",
    timeout: 10000
});

const noConnectionNotification = (): Notification => ({
    id: "NoConnectionNotification",
    dismissible: true,
    message: "Se ha perdido la conexion, intentando reconectar.",
    type: "warning"
});

const failedToInitSessionNotification = (error: string): Notification => ({
    id: `FailedToInitSessionNotification`,
    dismissible: true,
    message: `Ha ocurrido un error interno. ${error}. Por favor intenta mas tarde.`,
    type: "error"
});

export const notifications = {
    fileAttachmentAlreadyAttachedNotification,
    fileAttachmentInvalidSizeNotification,
    fileAttachmentInvalidTypeNotification,
    fileDownloadInvalidSizeNotification,
    fileDownloadInvalidTypeNotification,
    noConnectionNotification,
    failedToInitSessionNotification
};
